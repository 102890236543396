import React, {useState, useEffect} from 'react'

const FxMatrix = () => {
    const [exchange_rates, setExchangeRates] = useState([])
    const [fx_index_matrix, setFXMatrix] = useState([])

    useEffect(() => {    
        async function fetchFXData() {
            const url = 'https://exocoetidae.no/administrator/api/exchange-rate/fx-week-matrix'
            const headers = {
                'Authorization': '558ef807-ad2b-4aba-8629-c8d227745493'
            } 
            const FXDataResponse = await fetch(url, {headers:headers}).then(res => res.json())
            console.log(FXDataResponse.results, '---------------')
            setExchangeRates(FXDataResponse.results.exchange_rates)
            setFXMatrix(FXDataResponse.results.fx_index_matrix)
        }
        fetchFXData()
    }, [])

    return (
        <div>
            <h3>USD RATES</h3>
            <ul>
                {exchange_rates.map((record) => (
                    <li id={record.week}>{record.week}: {record.fx}</li>
                ))}
            </ul>
            <style>{`
                table tr td {
                    border:1px solid black;
                }
            `}</style>
            <table>
                {fx_index_matrix.map((row) => (
                    <tr>
                        {row.map((item) => (
                            <td>{item != null ? item : '—'}</td>
                        ))}
                    </tr>
                ))}
                <tr>
                </tr>

            </table>
        </div>
    )
}

export default FxMatrix
