import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {FaFilePdf} from 'react-icons/fa'

const PurchaseLog = () => {
    const [purchases, setPurchases] = useState([])

    useEffect(() => {    
        async function fetchPurchaseLog() {
            const url = 'https://exocoetidae.no/administrator/api/purchase-log/'
            const headers = {
                'Authorization': '558ef807-ad2b-4aba-8629-c8d227745493'
            } 
            const purchaseLogResponse = await fetch(url, {headers:headers}).then(res => res.json())
            console.log(purchaseLogResponse)
            const purchases = purchaseLogResponse
            setPurchases(purchases)
            console.log(purchases)
        }
        fetchPurchaseLog()
    }, [])

    return (
        <div>
            <ul>
            {purchases.map((purchase) => (
                <li id={purchase.id}>{purchase.id}: 
                {purchase.invoice_date}—{purchase.sabrv}—{purchase.invoice_no}—{purchase.amount}
                {purchase.invoice ?
                <a href = {purchase.invoice} target = "_blank"> 
                    <FaFilePdf
                        size={16}
                        style={{
                            marginLeft: '10px',
                        }}
                        color={'grey'}
                    />
                </a> : ""}   
             </li>
            ))}  
            </ul>
        </div>
    )
}

export default PurchaseLog