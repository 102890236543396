import React, {useState, useEffect} from 'react'

const ShipmentIncidents = ({currency}) => {
    const [classification, setClassification] = useState([])

    useEffect(() => {    
        async function fetchAPI() {
            const url = 'https://exocoetidae.no/incident-manager/api/shipment-incident/'
            const headers = {
                'Authorization': '558ef807-ad2b-4aba-8629-c8d227745493'
            }
            const APIResponse = await fetch(url, {headers:headers}).then(res => res.json())
            const classification = APIResponse.result
            setClassification(classification)
        }
        fetchAPI()
    }, [])

    return (
        <div>
            <ul>
                {classification.map((cls) => (
                    <li id={cls.id}>{cls.incident}</li>
                ))}
            </ul>
        </div>
    )
}

export default ShipmentIncidents
