import React, { useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';
import OrderVolumeMatrixComponent from '../components/Matrix/OrderVolumeMatrix';
import TenderServices from '../api/TenderServices';
import matrixWithToggleSwitch from '../hoc/matrixWithToggleSwitch';
import CommonServices from '../api/CommonServices';
import GlobalMatrixCard from '../components/GlobalMatrixCard/GlobalMatrixCard';
const TenderMatrix = matrixWithToggleSwitch(OrderVolumeMatrixComponent, {
	title: 'Tender Matrix',
	tableStyles:{
		overflow:'scroll'
	}
});
const AccumulatorMatrix = matrixWithToggleSwitch(OrderVolumeMatrixComponent, {
	title: 'Accumulator Matrix',
});
const ResidualVolumeMatrix = matrixWithToggleSwitch(
	OrderVolumeMatrixComponent,
	{ title: 'Residual Matrix' }
);

const OutlookVolumeMatrix = matrixWithToggleSwitch(OrderVolumeMatrixComponent, {
	title: 'Outlook Matrix',
});

export const ACTIONS = {
	SET_FIELDS: 'SetFields',
	GET_MATRIX: 'GetMatrix',
	GET_MATRIX_DATES: 'GetMatrixDates',
	SET_MATRIX_HEADER: 'SetMatrixHeader',
	ADD_TENDER: 'AddTender',
	RESET_TENDER: 'ResetTender',
	TOGGLE_OFF_MATRICES: 'ToggleOFFMatrices',
	SET_SELECTED_TENDERS: 'SetSelectedTenders',
	TOGGLE_ALL_MATRICES: 'ToggleAllMatrices',
	RESET_TOTAL_BOXES: 'ResetTotalBoxes',
	SET_WEEK: 'SET_WEEK',
	SET_DATES: 'SET_DATES',
	SHOW_MATRICES: 'ShowMatrices',
	HIDE_MATRICES: 'HIDE_MATRICES',
	UPDATE_ACTIVE_WEEK_DATA_STATUS: 'UPDATE_ACTIVE_WEEK_DATA_STATUS',
};

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.SET_FIELDS:
			return {
				...state,
				[action.payload.field]: action.payload.value,
			};

		case ACTIONS.GET_MATRIX:
			return {
				...state,
				initial_matrix_data: action.payload.initial_matrix_data,
				empty_matrix_data: action.payload.empty_matrix_data,
				initial_tender_data: action.payload.matrix_data,
				matrix_data: action.payload.matrix_data,
				residual_matrix_data: action.payload.residual_matrix_data,
				sourcing_week: action.payload.sourcing_week,
				shipment_week: action.payload.shipment_week,
			};
		case ACTIONS.GET_MATRIX_DATES:
			return {
				...state,
				sourcing_date: action.payload.sourcing_date,
				shipment_date: action.payload.shipment_date,
			};
		case ACTIONS.SET_MATRIX_HEADER:
			return {
				...state,
				total_matrix_volume: action.payload.total_volume,
				total_matrix_weight: action.payload.total_weight,
			};
		case ACTIONS.ADD_TENDER:
			return {
				...state,
				matrix_data: action.payload.matrix_data,
				show_tender_residual_matrices: action.payload.show_tender_residual_matrices,
				// button_enabled: false,
			};
		case ACTIONS.RESET_TENDER:
			return {
				...state,
				button_enabled: true,
				show_tender_residual_matrices: false,
				selected_tenders: [],
				matrix_data: {},
				// residual_matrix_data: {},
				toggle_residual_matrix: false,
				toggle_tender_matrix: false,
				toggle_outlook_matrix: false,
				volume_object: {},
				isAddClicked: false,
			};
		case ACTIONS.TOGGLE_OFF_MATRICES:
			return {
				...state,
				toggle_tender_matrix: false,
				toggle_residual_matrix: false,
			};
		case ACTIONS.SET_SELECTED_TENDERS:
			let incomingPayloads = {
				selected_tenders: action.payload.selected_tenders,
				button_enabled: true,
				show_tender_residual_matrices: true,
			};
			if (
				action.payload.selected_tenders.length === 0 &&
				!action.payload.show_all_matrixes
			) {
				return {
					...state,
					...incomingPayloads,
					show_tender_residual_matrices: false,
					toggle_residual_matrix: false,
					toggle_tender_matrix: false,
				};
			} else if (action.payload.selected_tenders >= 2) {
				return {
					...state,
					...incomingPayloads,
					button_enabled: false,
				};
			} else {
				return {
					...state,
					...incomingPayloads,
				};
			}
		case ACTIONS.TOGGLE_ALL_MATRICES:
			return {
				...state,
				// show_tender_residual_matrices: action.payload.value,
				toggle_tender_matrix: action.payload.value,
				toggle_outlook_matrix: action.payload.value,
				toggle_residual_matrix: action.payload.value,
			};

		case ACTIONS.RESET_TOTAL_BOXES:
			return {
				...state,
				total_matrix_volume: 0,
				total_matrix_weight: 0,
			};
		case ACTIONS.SET_WEEK:
			return {
				...state,
				selectedSourcingWeek: action.payload.selectedSourcingWeek,
			};
		case ACTIONS.SET_DATES:
			return {
				...state,
				sourcing_week: action.payload.sourcing_week,
				shipment_week: action.payload.shipment_week,
				current_week: action.payload.current_week,
				selectedSourcingWeek: action.payload.selectedSourcingWeek,
			};
		case ACTIONS.SHOW_MATRICES:
			return {
				...state,
				show_tender_residual_matrices: true,
			};
		case ACTIONS.HIDE_MATRICES:
			return {
				...state,
				show_tender_residual_matrices: false,
			};
		case ACTIONS.UPDATE_ACTIVE_WEEK_DATA_STATUS:
			return {
				...state,
				active_week_data_status: action.payload.update_active_week_data_status,
			};
		default:
			return {
				...state,
			};
	}
};

function TenderPage() {
	const [state, dispatch] = useReducer(reducer, {
		button_enabled: true,
		show_tender_residual_matrices: false,
		matrix_model: 'Tender',
		selected_tenders: [], // selected bubbles
		tender_list: [], // data retrieved from FetchTenderList
		matrix_data: {},
		initial_tender_data: {},
		initial_matrix_data: {}, // retrieve the initial response data.
		empty_matrix_data: {},
		residual_matrix_data: {},
		sourcing_week: '',
		shipment_week: '',
		current_week: '',
		sourcing_date: '',
		shipment_date: '',
		week_data: [],
		matrix_dimension_status: 3,
		volume_object: {},
		total_matrix_volume: 0,
		total_matrix_weight: 0,
		isAddClicked: false,
		isSigmaClicked: false,
		toggle_residual_matrix: false,
		toggle_tender_matrix: false,
		toggle_outlook_matrix: false,
		isMatrixChanged: false,
		selectedSourcingWeek: '',
		active_week_data_status: [],
	});

	//INITIAL LOAD
	useEffect(() => {
		fetchMatrix();
		// fetchTenderList();
	}, []);

	useEffect(() => {
		if (state.selectedSourcingWeek !== '') {
			fetchTenderList();
			fetchMatrix();
		} else {
			dispatch({
				type: ACTIONS.RESET_TENDER,
			});
		}
	}, [state.selectedSourcingWeek]);

	const calculateResidual = (matrixData) => {
		let temp_residual_matrix = JSON.parse(
			JSON.stringify({ ...state.initial_matrix_data })
		);

		for (const [key, value] of Object.entries(matrixData)) {
			value.map(
				(item, i) =>
					(temp_residual_matrix[key][i].volume = -(
						parseInt(state.initial_matrix_data[key][i].volume) -
						parseInt(matrixData[key][i].volume)
					))
			);
		}
		return temp_residual_matrix;
	};

	const renderEmptyMatrix = () => {
		let empty_matrix = JSON.parse(JSON.stringify(state.empty_matrix_data));
		Object.entries(empty_matrix).forEach((item) => {
			item[1].forEach((val, i) => {
				val.volume = 0;
			});
		});
		return empty_matrix;
	};

	//CALCULATION OF RESIDUAL MATRIX
	useEffect(() => {
		if (Object.keys(state.volume_object).length !== 0) {
			let temp_residualMatrix = calculateResidual(state.matrix_data);

			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'residual_matrix_data',
					value: temp_residualMatrix,
				},
			});
		}

		if (state.isAddClicked || state.selected_tenders.length > 0)
			calculateVolumes();
		else {
			dispatch({
				type: ACTIONS.RESET_TOTAL_BOXES,
			});
		}
	}, [state.matrix_data]);

	const getVolumes = (matrix) => {
		let sum = 0;
		Object.entries(matrix).map((item) =>
			item[1].map((val) => (sum += val.volume))
		);
		return sum;
	};

	//Calculate total number of box and volume
	const calculateVolumes = () => {
		dispatch({
			type: ACTIONS.SET_MATRIX_HEADER,
			payload: {
				total_volume: getVolumes(state.matrix_data),
				total_weight: getVolumes(state.matrix_data) * 22,
			},
		});
	};

	// Fetch the Tender List for bubbles.
	const fetchTenderList = async () => {
		dispatch({
			type: ACTIONS.RESET_TENDER,
		});
		const tenderList = await TenderServices.getTenderList(
			state.selectedSourcingWeek
		);
		if (tenderList.data) {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'tender_list',
					value: tenderList.data.results,
				},
			});
			dispatch({
				type: ACTIONS.SET_DATES,
				payload: {
					sourcing_week: tenderList.data.sourcing_week,
					shipment_week: tenderList.data.shipment_week,
					current_week: tenderList.data.current_week,
					selectedSourcingWeek: tenderList.data.sourcing_week,
				},
			});
		} else {
			console.error(tenderList.error);
		}
	};

	// Fetch the initial Data to tender Matrix.
	const fetchMatrix = async () => {
		let apiData = {
			model: 'Tender',
			model_ids: state.selected_tenders,
			comparison: 'outlook',
			comparison_ids: null,
			sourcing_week: state.selectedSourcingWeek,
		};
		if (state.selectedSourcingWeek) {
			apiData['matrix_week'] = state.selectedSourcingWeek;
		}
		const matrixData = await CommonServices.getMatrixDataByComparison(apiData);
		if (matrixData.data) {
			const { data } = matrixData;
			dispatch({
				type: ACTIONS.GET_MATRIX,
				payload: {
					initial_matrix_data: data.outlook_matrix,
					empty_matrix_data: data.outlook_matrix,
					matrix_data: data.accumulated_matrix,
					residual_matrix_data: data.residual_matrix, // needs to be changed to residual matrix with full zero initially.
					sourcing_week: data.sourcing_week,
					shipment_week: data.shipment_week,
				},
			});

			if (state.selected_tenders.length > 0) calculateVolumes();
			else {
				dispatch({
					type: ACTIONS.RESET_TOTAL_BOXES,
				});
				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'matrix_data',
						value: renderEmptyMatrix(),
					},
				});
				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'residual_matrix_data',
						value: calculateResidual(renderEmptyMatrix()),
					},
				});
			}

			dispatch({
				type: ACTIONS.GET_MATRIX_DATES,
				payload: {
					sourcing_date: data.sourcing_date,
					shipment_date: data.shipment_date,
				},
			});

			// dispatch({
			// 	type: ACTIONS.SHOW_MATRICES,
			// });

			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'week_data',
					value: data.matrix_date_list,
				},
			});
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'button_enabled',
					value: true,
				},
			});
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'isMatrixChanged',
					value: false,
				},
			});

			if (state.isAddClicked) {
				// dispatch({
				// 	type: ACTIONS.SET_FIELDS,
				// 	payload: {
				// 		field: 'toggle_tender_matrix',
				// 		value: false,
				// 	},
				// });
				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'show_tender_residual_matrices',
						value: false,
					},
				});
				dispatch({
					type: ACTIONS.RESET_TOTAL_BOXES,
				});
			}
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'isAddClicked',
					value: false,
				},
			});
		}
	};

	//Calls when tender matrix input volume changes
	const updateVolumeFieldMatrix = (volumeObj) => {
		console.log('Volume', volumeObj);

		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'volume_object',
				value: volumeObj,
			},
		});

		let tempMatrix;

		tempMatrix = JSON.parse(
			JSON.stringify({ ...state.matrix_data }) // need to be change matrix_data -> initial_matrix_data
		);

		tempMatrix[volumeObj.weight_class][volumeObj.dayIndex - 1].volume = parseInt(
			volumeObj.volume
		);

		let checkNotNull = !Object.values(tempMatrix).every((o) => o === null);
		const inputChange =
			JSON.stringify(state.initial_tender_data) === JSON.stringify(tempMatrix); // need to be change matrix_data -> initial_matrix_data
		if (state.isAddClicked) {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'button_enabled',
					value: !checkNotNull,
				},
			});
		} else {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'button_enabled',
					value: !checkNotNull || inputChange,
				},
			});
		}

		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'isMatrixChanged',
				value: !inputChange,
			},
		});

		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'matrix_data',
				value: tempMatrix,
			},
		});
	};

	//Calls when Tender bubble is Clicked.
	const handleBubble = async (selectedBubbles) => {
		console.log('selected Bubbles', selectedBubbles);

		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'isAddClicked',
				value: false,
			},
		});

		// dispatch({
		// 	type: ACTIONS.SET_FIELDS,
		// 	payload: {
		// 		field: 'toggle_tender_matrix',
		// 		value: true,
		// 	},
		// });

		dispatch({
			type: ACTIONS.SET_SELECTED_TENDERS,
			payload: {
				selected_tenders: selectedBubbles,
				show_all_matrixes: state.isSigmaClicked,
			},
		});

		const matrixData = await CommonServices.getMatrixDataByComparison({
			model: 'Tender',
			model_ids: selectedBubbles,
			comparison: 'outlook',
			comparison_ids: null,
			matrix_week: state.selectedSourcingWeek,
		});
		if (matrixData.data) {
			let { data } = matrixData;

			// if (selectedBubbles.length === 0) {
			// 	console.log('this is working');
			// 	Object.entries(empty_matrix).forEach((item) => {
			// 		item[1].forEach((val, i) => {
			// 			val.volume = 0;
			// 		});
			// 	});
			// }

			dispatch({
				type: ACTIONS.GET_MATRIX,
				payload: {
					initial_matrix_data: data.outlook_matrix,
					empty_matrix_data: data.outlook_matrix,
					matrix_data:
						selectedBubbles.length === 0
							? renderEmptyMatrix()
							: data.accumulated_matrix,
					residual_matrix_data: data.residual_matrix, // needs to be changed to residual matrix with full zero initially.
					sourcing_week: data.sourcing_week,
					shipment_week: data.shipment_week,
				},
			});

			dispatch({
				type: ACTIONS.ADD_TENDER,
				payload: {
					matrix_data:
						selectedBubbles.length === 0
							? renderEmptyMatrix()
							: data.accumulated_matrix,
					show_tender_residual_matrices: selectedBubbles.length === 0 ? false : true,
				},
			});

			if (selectedBubbles.length === 0) {
				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'residual_matrix_data',
						value: calculateResidual(
							selectedBubbles.length === 0
								? renderEmptyMatrix()
								: data.accumulated_matrix
						),
					},
				});
			}

			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'button_enabled',
					value: true,
				},
			});
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'week_data',
					value: data.matrix_date_list,
				},
			});
		}
	};

	//calls when + button in Bubble clicked.
	const addTenderMatrix = () => {
		// empty volumes for adding new tender
		// Object.keys(state.initial_matrix_data).map((key)=>{
		// 	state.empty_matrix_data[key].map((val)=>{
		// 		val.volume = 0
		// 	})
		// })

		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'isAddClicked',
				value: true,
			},
		});
		// dispatch({
		// 	type: ACTIONS.SET_FIELDS,
		// 	payload: {
		// 		field: 'toggle_tender_matrix',
		// 		value: true,
		// 	},
		// });
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'selected_tenders',
				value: [],
			},
		});
		dispatch({
			type: ACTIONS.ADD_TENDER,
			payload: {
				matrix_data: state.initial_matrix_data,
				show_tender_residual_matrices: true,

				// matrix_data: state.empty_matrix_data,
			},
		});
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'residual_matrix_data',
				value: renderEmptyMatrix(),
			},
		});
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'button_enabled',
				value: false,
			},
		});
		// dispatch({
		// 	type: ACTIONS.SET_FIELDS,
		// 	payload: {
		// 		field: 'show_tender_residual_matrices',
		// 		value: true,
		// 	},
		// });
	};

	//calls when delete button for a tender is clicked.
	const deleteTender = async () => {
		const deleteTender = await TenderServices.deleteTender(
			state.selected_tenders
		);
		if (deleteTender.data) {
			dispatch({
				type: ACTIONS.RESET_TENDER,
			});
			fetchTenderList();
		} else {
			console.log(deleteTender.error);
		}
	};

	//Calls when Update button clicked
	const updateVolume = async () => {
		const data = {
			product_id: 1,
			is_8th_day: false,
			data: state.matrix_data,
		};

		const updateMatrixData = await TenderServices.updateMatrixData(
			state.selected_tenders.length === 1 ? state.selected_tenders : '0',
			data
		);
		if (updateMatrixData.data) {
			dispatch({
				type: ACTIONS.RESET_TENDER,
			});

			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'isMatrixChanged',
					value: false,
				},
			});

			fetchMatrix();
			fetchTenderList();
		} else {
			console.log(updateMatrixData.error);
		}
	};

	//Calls when Sigma button Clicked
	const handleSigma = (isSigma) => {
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'isAddClicked',
				value: false,
			},
		});

		if (state.selected_tenders.length === 0) {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'matrix_data',
					value: renderEmptyMatrix(),
				},
			});
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'residual_matrix_data',
					value: calculateResidual(renderEmptyMatrix()),
				},
			});
		}

		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'button_enabled',
				value: true,
			},
		});
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'isSigmaClicked',
				value: isSigma,
			},
		});
		if (!isSigma) {
			dispatch({
				type: ACTIONS.HIDE_MATRICES,
			});
		}
	};
	useEffect(() => {
		dispatch({
			type: ACTIONS.TOGGLE_ALL_MATRICES,
			payload: {
				value: false,
			},
		});
		console.log('state.isSigmaClicked', state.isSigmaClicked);
		if (state.isSigmaClicked) {
			dispatch({
				type: ACTIONS.TOGGLE_ALL_MATRICES,
				payload: {
					value: true,
				},
			});
			dispatch({
				type: ACTIONS.SHOW_MATRICES,
			});
		} else {
			if (state.isAddClicked || state.selected_tenders.length)
				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'toggle_tender_matrix',
						value: true,
					},
				});
		}
	}, [
		state.isSigmaClicked,
		JSON.stringify(state.selected_tenders),
		state.isAddClicked,
	]);

	const handleToggleStatus = (toggleStatus, toggleType) => {
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: toggleType,
				value: toggleStatus,
			},
		});
	};

	const onWeekClick = (e) => {
		dispatch({
			type: 'SET_WEEK',
			payload: { selectedSourcingWeek: e.target ? e.target.value : e },
		});
	};

	const refreshButtonHandle = () => {
		if (state.isSigmaClicked) {
			dispatch({
				type: ACTIONS.TOGGLE_ALL_MATRICES,
				payload: {
					value: true,
				},
			});
		}
		if (state.isAddClicked || state.selected_tenders.length) {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'toggle_tender_matrix',
					value: true,
				},
			});
		}
	};

	const updateActiveWeekDataStatus = (data) => {
		dispatch({
			type: 'UPDATE_ACTIVE_WEEK_DATA_STATUS',
			payload: { update_active_week_data_status: data },
		});
	};

	return (
		<>
			<section className='sell_body_wrap'>
				{/* <Sidebar /> */}
				<div style={{ paddingBottom: 30 }} className='tender_fc_manager'>
					<div className='scrl_out' style={{ paddingBottom: '30px' }}>
						<Link to={'/home'}>
							<p className={'Back_link'}>Back</p>
						</Link>
						<GlobalMatrixCard
							buttonStyleType='flex'
							enabledComponents={[
								'HeadCaption',
								'ButtonComponent',
								'BubbleSelector',
								'MatrixTotals',
								'WeekComponets',
								'WeekSelector',
							]}
							headerCaption={'Tender'}
							orderTotalBox={state.total_matrix_volume}
							dispalyOrderTotalBox={true}
							orderTotalWeight={state.total_matrix_weight}
							displayOrderTotalWeight={true}
							updateButtonDisplay={true}
							updateButtonDisable={state.button_enabled}
							updateButtonHandle={updateVolume}
							refreshButtonDisplay={true}
							refreshButtonHandle={() => {
								fetchMatrix();
								refreshButtonHandle();
							}}
							sourcingWeekDisplay={true}
							sourcingWeek={state.sourcing_week}
							sourcingDates={state.sourcing_date}
							shipmentWeekDisplay={true}
							shipmentWeek={state.shipment_week}
							shipmentDates={state.shipment_date}
							currentWeek={state.current_week}
							bubbleSelectorTitle={'Tenders'}
							bubbleSelectorBubbleArray={state.tender_list}
							bubbleSelectorSelectedBubbles={state.selected_tenders}
							bubbleSelectorHandleAddBubble={addTenderMatrix}
							bubbleSelectorHandleDeleteBubble={deleteTender}
							bubbleSelectorHandleBubbleSelect={handleBubble}
							bubbleSelectorHandleSigma={handleSigma}
							bubbleSelectorMatrixChangedStatus={state.isMatrixChanged}
							bubbleSelectorAddBtnClicked={state.isAddClicked}
							bubbleSelectorisAddVisibility={
								state.current_week === state.sourcing_week
							}
							//week selector
							access_type={''}
							access_id={''}
							modelName={'Tender'}
							updateActiveWeekDataStatus={updateActiveWeekDataStatus}
							selectedSourcingWeek={state.selectedSourcingWeek}
							onWeekClick={onWeekClick}
							is_disabled={state.isMatrixChanged || state.isSigmaClicked}
							MatixIconDisplay={true}
							showActiveMatrixIconDisplay={
								state.tender_list.length > 0 &&
								state.active_week_data_status.includes(true)
									? true
									: false
							}
						/>
						<OutlookVolumeMatrix
							orderVolumeMatrix={state.initial_matrix_data}
							type='view'
							state={state}
							daysOrder={state.week_data}
							onUpdate={updateVolumeFieldMatrix}
							toggleSwitchDisabled={false}
							toggleStatus={state.toggle_outlook_matrix}
							handleToggleStatus={(status) =>
								handleToggleStatus(status, 'toggle_outlook_matrix')
							}
						/>
						{state.selected_tenders.length > 1 ||
						state.isSigmaClicked ||
						state.current_week !== state.selectedSourcingWeek ? (
							<AccumulatorMatrix
								orderVolumeMatrix={state.matrix_data}
								state={state}
								daysOrder={state.week_data}
								onUpdate={updateVolumeFieldMatrix}
								type='view'
								toggleSwitchDisabled={!state.show_tender_residual_matrices}
								toggleStatus={state.toggle_tender_matrix}
								handleToggleStatus={(status) =>
									handleToggleStatus(status, 'toggle_tender_matrix')
								}
							/>
						) : (
							<TenderMatrix
								orderVolumeMatrix={state.matrix_data}
								enableUpdate={true}
								state={state}
								daysOrder={state.week_data}
								onUpdate={updateVolumeFieldMatrix}
								type=''
								toggleSwitchDisabled={!state.show_tender_residual_matrices}
								toggleStatus={state.toggle_tender_matrix}
								handleToggleStatus={(status) =>
									handleToggleStatus(status, 'toggle_tender_matrix')
								}
							/>
						)}

						<ResidualVolumeMatrix
							orderVolumeMatrix={state.residual_matrix_data}
							type='view'
							state={state}
							daysOrder={state.week_data}
							onUpdate={updateVolumeFieldMatrix}
							toggleSwitchDisabled={!state.show_tender_residual_matrices}
							toggleStatus={state.toggle_residual_matrix}
							handleToggleStatus={(status) =>
								handleToggleStatus(status, 'toggle_residual_matrix')
							}
						/>
					</div>
				</div>
			</section>
		</>
	);
}
export default TenderPage;
