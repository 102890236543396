import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import BrowserStorage from '../utils/BrowserStorage';


const CleanDeskPage = () => {

    const [clean_desk_records, setCleanDeskRecords] = useState([])
    const [response_list, setResponseList] = useState([]);
    const userInfo = BrowserStorage.getValue('userInfo');

    // const [counter, setCounter] = useState(0)  

    const getCleanDesk = () => {
        const webApiUrl = 'https://exocoetidae.no/clean-desk/api/clean-desk/'
        const headers = {
            'Authorization': '558ef807-ad2b-4aba-8629-c8d227745493'
        } 
        axios.get(webApiUrl, {
            headers: headers
        })
        .then(response => {
            setCleanDeskRecords(response.data.result);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getResponseList = () => {
        const webApiUrl = 'https://exocoetidae.no/business/api/response-list/'
        const headers = {
            'Authorization': '558ef807-ad2b-4aba-8629-c8d227745493'
        } 
        axios.get(webApiUrl, {
            headers: headers
        })
        .then(response => {
            setResponseList(response.data.result);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if(userInfo.userName.startsWith("Ronny"))    
            getCleanDesk();
            // getResponseList();
    }, [])

    const timerId = setInterval(() => {
        {clean_desk_records.map((record) => (
            store.addNotification({
                title: record.topic,
                message: record.deadline,
                type: "success",
                container: "top-right",
                insert: "top",
                dismiss: {
                    duration: 5000
                }
            })
        ))} 
        // {response_list.map((record) => (
        //     store.addNotification({
        //         title: record,
        //         message: "Please respond to email",
        //         type: "info",
        //         container: "top-right",
        //         insert: "top",
        //         dismiss: {
        //             duration: 5000
        //         }
        //     })
        // ))}        
    }, 40000)


    return (
        <div className="app-container">
            <ReactNotification />
            <section className={'body_wrap'}>
                {/* <Sidebar /> */}
                <div className={'cont_wp'}>
                    <Link to={'/home'}>
                        <p className={'Back_link'}>Back</p>
                    </Link>
                    <section className={'cmn_head'}>
                        <p>Clean Desk</p>
                    </section>
                </div>
            </section>
        </div>

    )
}

export default CleanDeskPage

