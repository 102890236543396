import React, { useEffect, useReducer } from 'react';
import { Fragment } from 'react';
import { MdModeEdit } from 'react-icons/md';
import { FiChevronRight } from 'react-icons/fi';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { ToastsStore } from 'react-toasts';
import { confirmAlert } from 'react-confirm-alert'; // Import

import CalendarServices from '../../api/CalendarServices';
import RFQServices from '../../api/RFQServices';
// import TenderServices from '../../pages/api/TenderServices';
import Sidebar from '../Sidebar/Sidebar';
import './PurchaseDetail.css';
import GlobalMatrixCard from '../GlobalMatrixCard/GlobalMatrixCard';
import PurchaseServices from '../../api/PurchaseServices';
import { formatCurrency } from '../../util';
import CommonServices from '../../api/CommonServices';

const initialState = {
	table_header_weeks: [],

	matrix_data_9_1: {},
	matrix_data_9_7: {},
	matrix_data_9_8: {},
	retrieved_matrix_9_8: {},
	display_matrix: {},
	matrix_row_total: {},
	shipment_date: '',
	weight_classes: [],
	each_row_price_total: {}, //active_admin_offer_full - BE
	each_row_volume_total: {}, //weight_class_volumes - BE
	matrix_id: '',

	//HEADER PART STATES
	is_update_enabled: false,
	total_matrix_volume: '',
	total_matrix_weight: '',
	total_matrix_usd: '',
	sourcing_week: '',
	sourcing_date: '',
	shipment_week: '',
	enable_subtractor: false,
	retrieved_matrix_dimensional_status: 3,
	matrix_dimension_status: 3,
	matrix_volume_update: false,
	refresh_button_clicked: false,
	rejection_status: '',

	//NINE STAR SELECTOR
	selected_nine_star: '9_1',

	headerInfo: {
		caption: 'Purchase Detail',
		subCaption: '',
	},
	enabledComponents: [
		'HeadCaption',
		'ButtonComponent',
		'MatrixTotals',
		'WeekComponets',
		'NineStarSelector',
		'SplitSelector',
		'WeekSelector',
	],
	//week selector
	selectedSourcingWeek: '',
	current_week: '',
	active_week_data_status: [],
};
export const ACTIONS = {
	SET_FIELDS: 'SetFields',
	GET_PURCHASE_MATRIX: 'GET_PURCHASE_MATRIX',
	SET_DATES: 'SetDates',
	SET_VOLUME_AND_WEIGHT: 'SetVolumeAndWeight',
	CHANGE_MATRIX_DIMENSION: 'CHANGE_MATRIX_DIMENSION',
	SPLIT_SWITCHER: 'SPLIT_SWITCHER',
	SET_WEEK: 'SET_WEEK',
	UPDATE_ACTIVE_WEEK_DATA_STATUS: 'UPDATE_ACTIVE_WEEK_DATA_STATUS',
};
const renderNineStar = (status) => {
	switch (status) {
		case 1:
			return '9_1';
		case 2:
			return '9_7';
		case 3:
			return '9_8';

		default:
			return '9_7';
	}
};
const renderDisplayMatrix = (
	matrix_dimension_status,
	newMatrixData,
	oldMatrixData
) => {
	switch (matrix_dimension_status) {
		case 1:
			return newMatrixData.matrix_data_9_1 || oldMatrixData.matrix_data_9_1;

		case 2:
			return newMatrixData.matrix_data_9_7 || oldMatrixData.matrix_data_9_7;

		case 3:
			return newMatrixData.matrix_data_9_8 || oldMatrixData.matrix_data_9_8;
		default:
			return newMatrixData.matrix_data_9_7 || oldMatrixData.matrix_data_9_7;
	}
};
const renderMatrixDimension = (dimension) => {
	switch (dimension) {
		case '9_1':
			return 1;
		case '9_7':
			return 2;
		case '9_8':
			return 3;

		default:
			return 2;
	}
};
const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTIONS.SET_FIELDS:
			return {
				...state,
				[payload.field]: payload.value,
			};
		case ACTIONS.SET_DATES:
			return {
				...state,
				sourcing_week: payload.sourcing_week,
				sourcing_date: payload.sourcing_date,
				shipment_week: payload.shipment_week,
				current_week: payload.current_week,
			};
		case ACTIONS.SET_VOLUME_AND_WEIGHT:
			return {
				...state,
				total_matrix_volume: payload.total_matrix_volume,
				total_matrix_weight: payload.total_matrix_volume * 22,
			};

		case ACTIONS.GET_PURCHASE_MATRIX:
			return {
				...state,
				table_header_weeks: payload.table_header_weeks,
				matrix_data_9_8: payload.matrix_data_9_8,
				retrieved_matrix_9_8: payload.matrix_data_9_8,
				weight_classes: Object.keys(payload.matrix_data_9_8),
				matrix_data_9_7: payload.matrix_data_9_7,
				matrix_data_9_1: payload.matrix_data_9_1,
				matrix_id: payload.matrix_id,
				display_matrix: renderDisplayMatrix(
					payload.matrix_dimension_status,
					payload,
					state
				),
				selected_nine_star: renderNineStar(payload.matrix_dimension_status),
				matrix_dimension_status: payload.matrix_dimension_status,
				shipment_date: payload.shipment_date,
				each_row_volume_total: payload.each_row_volume_total,
				each_row_price_total: payload.each_row_price_total,
				disable_options_nine_star:
					payload.matrix_dimension_status !== 2 ? ['9_7'] : [],
				retrieved_matrix_dimensional_status: payload.matrix_dimension_status,
				rejection_status: payload.rejection_status,
				selectedSourcingWeek: payload.selectedSourcingWeek,
				sourcing_week: payload.sourcing_week,
				shipment_week: payload.shipment_week,
				current_week: payload.current_week,
				sourcing_date: payload.sourcing_date,
			};
		case ACTIONS.CHANGE_MATRIX_DIMENSION:
			let dimension_status = renderMatrixDimension(payload.selected_nine_star);
			return {
				...state,
				selected_nine_star: payload.selected_nine_star,
				matrix_dimension_status: dimension_status,
				display_matrix: renderDisplayMatrix(dimension_status, payload, state),
			};
		case ACTIONS.SPLIT_SWITCHER:
			return {
				...state,
				is_split_disabled: false,
				matrix_dimension_status: 3,
				display_matrix: state.matrix_data_9_8,
				selected_nine_star: '9_8',
				disable_options_nine_star: ['9_1', '9_7'],
			};
		case ACTIONS.SET_WEEK:
			return {
				...state,
				selectedSourcingWeek: payload.selectedSourcingWeek,
			};
		case ACTIONS.UPDATE_ACTIVE_WEEK_DATA_STATUS:
			return {
				...state,
				active_week_data_status: payload.update_active_week_data_status,
			};
		default:
			return {
				...state,
			};
	}
};
function PurchaseDetail(props) {
	// const { store, dispatch } = props;
	const [state, dispatch] = useReducer(reducer, initialState);

	let { access_id, type, purchase_id } = useParams();
	let history = useHistory();

	useEffect(() => {
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'is_update_enabled',
				value: state.matrix_volume_update
					? false
					: state.retrieved_matrix_dimensional_status !==
					  state.matrix_dimension_status
					? false
					: true || false,
			},
		});
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'disable_options_nine_star',
				value: state.matrix_volume_update
					? ['9_1', '9_7', '9_8']
					: state.enable_subtractor
					? ['9_7', '9_1']
					: state.matrix_dimension_status !== 2 &&
					  state.retrieved_matrix_dimensional_status !== 2
					? ['9_7']
					: [],
			},
		});
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'is_split_disabled',
				value: state.matrix_volume_update || false,
			},
		});
	}, [state.matrix_volume_update]);
	useEffect(() => {
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'is_split_disabled',
				value: state.retrieved_matrix_dimensional_status === 2 ? true : false,
			},
		});
		if (
			state.retrieved_matrix_dimensional_status !== state.matrix_dimension_status
		)
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'is_update_enabled',
					value: false,
				},
			});
		else {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'is_update_enabled',
					value: true,
				},
			});
		}
		if (
			state.matrix_dimension_status === 3 &&
			state.retrieved_matrix_9_8 === state.matrix_data_9_8
		) {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'is_update_enabled',
					value: true,
				},
			});
		}
	}, [
		state.matrix_dimension_status,
		state.refresh_button_clicked,
		// state.display_matrix,
	]);
	const handleRefreshButtonClicked = () => {
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'refresh_button_clicked',
				value: !state.refresh_button_clicked,
			},
		});
		history.go(0);
	};

	const updateMatrix = async () => {
		const data = {
			access_id: access_id,
			access_type: type,
			data: state.display_matrix,
			nullify_existing:
				state.retrieved_matrix_dimensional_status === state.matrix_dimension_status
					? false
					: true,
			is_8th_day: false,
		};
		const updateData = await PurchaseServices.updatePurchaseMatrix(
			purchase_id,
			data
		);
		if (updateData && updateData.data) {
			let { data } = updateData;
			handleRefreshButtonClicked();

			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'is_update_enabled',
					value: true,
				},
			});
		}
	};
	const handleStarSelector = (value) => {
		// dispatch({
		// 	type: ACTIONS.SET_FIELDS,
		// 	payload: {
		// 		field: 'selected_nine_star',
		// 		value,
		// 	},
		// });
		dispatch({
			type: ACTIONS.CHANGE_MATRIX_DIMENSION,
			payload: {
				selected_nine_star: value,
			},
		});
	};
	const handleSplitChange = (splitValue) => {
		if (state.matrix_dimension_status !== 2) {
			dispatch({
				type: ACTIONS.SPLIT_SWITCHER,
			});
			if (splitValue)
				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'enable_subtractor',
						value: splitValue,
					},
				});
			else {
				if (state.retrieved_matrix_dimensional_status !== 2) {
					dispatch({
						type: ACTIONS.SET_FIELDS,
						payload: {
							field: 'disable_options_nine_star',
							value: ['9_7'],
						},
					});
				} else
					dispatch({
						type: ACTIONS.SET_FIELDS,
						payload: {
							field: 'disable_options_nine_star',
							value: [],
						},
					});
				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'enable_subtractor',
						value: splitValue,
					},
				});
			}
		}
	};

	const handleEmailButton = () => {
		console.log('email button clicked');
		confirmAlert({
			message: 'Here comes Email, SMS, Whatsapp and feed functionality ',
		});
	};
	const handleDocumentButton = () => {
		console.log('Document button clicked');

		window.open('https://exocoetidae.no/media/bitfish/rfq_doc.pdf', '_blank');
	};

	const onWeekClick = (e) => {
		dispatch({
			type: 'SET_WEEK',
			payload: { selectedSourcingWeek: e.target ? e.target.value : e },
		});
	};

	const updateActiveWeekDataStatus = (data) => {
		dispatch({
			type: 'UPDATE_ACTIVE_WEEK_DATA_STATUS',
			payload: { update_active_week_data_status: data },
		});
	};

	return (
		<>
			<section className='sell_body_wrap'>
				{/* <Sidebar /> */}
				<div style={{ paddingBottom: 30 }} className='purchase_details_fc'>
					<div className='scrl_out' style={{ paddingBottom: '30px' }}>
						<Link to={'/buy/purchase'}>
							<p className={'Back_link'}>Back</p>
						</Link>
					</div>
					<GlobalMatrixCard
						//header information
						headerCaption={state.headerInfo.caption}
						headerSubCaption={state.headerInfo.subCaption}
						enabledComponents={state.enabledComponents}
						// Nine star selector
						nineStarDisplay={
							state.current_week == state.selectedSourcingWeek ||
							(state.matrix_id !== 0 && state.selectedSourcingWeek !== '')
								? true
								: false
						}
						nineStarSelectedDimension={state.selected_nine_star}
						nineStarHandleChangeDimension={(e) => {
							console.log('selector clicked', e);
							handleStarSelector(e);
						}}
						nineStarDisabledOtpions={
							(state.current_week !== state.selectedSourcingWeek && [
								'9_7',
								'9_1',
								'9_8',
							]) ||
							state.disable_options_nine_star ||
							[]
						}
						matrix_id={state.matrix_id}
						//split selector
						splitDisplay={
							state.current_week == state.selectedSourcingWeek ||
							(state.matrix_id !== 0 && state.selectedSourcingWeek !== '')
								? true
								: false
						}
						splitSelectorChecked={state.enable_subtractor}
						splitSelectorHandleChange={(e) => {
							handleSplitChange(!state.enable_subtractor);
						}}
						splitSelectorDisabled={
							state.current_week !== state.selectedSourcingWeek ||
							state.retrieved_matrix_dimensional_status === 2
								? true
								: state.is_split_disabled
						}
						//matrix totals
						orderTotalBox={state.total_matrix_volume}
						dispalyOrderTotalBox={true}
						orderTotalWeight={state.total_matrix_weight}
						displayOrderTotalWeight={true}
						orderTotalUSD={state.total_matrix_usd}
						displayOrderTotalUSD={true}
						priceInfo={{ currencyType: 'NOK' }}
						//Week component
						sourcingWeek={state.sourcing_week}
						sourcingDates={state.sourcing_date}
						shipmentWeek={state.shipment_week}
						shipmentDates={state.shipment_date}
						sourcingWeekDisplay={true}
						shipmentWeekDisplay={true}
						currentWeek={state.current_week}
						// matrix Buttons
						updateButtonDisplay={true}
						updateButtonDisable={state.is_update_enabled}
						updateButtonHandle={() => {
							updateMatrix();
						}}
						refreshButtonDisplay={true}
						refreshButtonHandle={handleRefreshButtonClicked}
						emailButtonDisplay={true}
						emailButtonHandle={handleEmailButton}
						documentButtonDisplay={true}
						documentButtonHandle={handleDocumentButton}
						//week selector
						access_type={'x' + type}
						access_id={access_id}
						modelName={'Purchase'}
						updateActiveWeekDataStatus={updateActiveWeekDataStatus}
						selectedSourcingWeek={state.selectedSourcingWeek}
						onWeekClick={onWeekClick}
						is_disabled={state.matrix_volume_update || state.enable_subtractor}
						MatixIconDisplay={true}
						showActiveMatrixIconDisplay={
							state.matrix_id == 0 && state.selectedSourcingWeek !== ''
								? false
								: state.active_week_data_status
								? state.active_week_data_status.includes(true)
									? true
									: false
								: false
						}
					/>
					<QuoteTable {...props} state={state} dispatch={dispatch} />
				</div>
			</section>
		</>
	);
}

const QuoteTable = (props) => {
	const { state, dispatch } = props;
	let { access_id, type, purchase_id } = useParams();
	const fetchDesiredMatrix = () => {
		// const fetchedDetails = async () => await CalendarServices.getWeekDates();

		// fetchedDetails()
		// 	.then((res) => {
		// 		const { data } = res;
		// 		dispatch({
		// 			type: ACTIONS.SET_DATES,
		// 			payload: {
		// 				sourcing_week: data.sourcing_week,
		// 				sourcing_date: data.sourcing_date,
		// 				shipment_week: data.shipment_week,
		// 				current_week: data.current_week
		// 			},
		// 		});
		fetchPurchaseMatrix(access_id, type);
		// })
		// .catch((err) => console.log('fetched Error', err));
	};

	useEffect(() => {
		if (state.selectedSourcingWeek !== '') {
			fetchDesiredMatrix();
		} else {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'is_update_enabled',
					value: true,
				},
			});
		}
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'enable_subtractor',
				value: false,
			},
		});
	}, [state.selectedSourcingWeek, state.refresh_button_clicked]);

	useEffect(() => {
		let sum_volumes = 0;

		let temp_each_row_price_total = state.each_row_price_total;
		let total_usd = 0;

		if (
			state.matrix_id !== 0 &&
			state.display_matrix &&
			Object.keys(state.display_matrix).length
		) {
			Object.entries(state.display_matrix).forEach((item) => {
				if (temp_each_row_price_total[item[0]] !== null) {
					let row_volumes = 0;
					item[1].map((val) => {
						row_volumes += val.volume;
					});
					total_usd += row_volumes * 22 * temp_each_row_price_total[item[0]];
				}
			});

			Object.keys(state.display_matrix).length > 0 &&
				Object.entries(state.display_matrix).forEach((item, index) => {
					item[1].map((val) => {
						sum_volumes += val.volume;
					});
				});
		}

		dispatch({
			type: ACTIONS.SET_VOLUME_AND_WEIGHT,
			payload: {
				total_matrix_volume: sum_volumes,
			},
		});
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'total_matrix_usd',
				value: total_usd,
			},
		});
	}, [state.display_matrix]);

	// fetch Purchase Matrix
	const fetchPurchaseMatrix = async (access_id, type) => {
		let apiData = {
			access_type: 'x' + type,
			model_name: 'Purchase',
			access_id: access_id,
			sourcing_week: state.selectedSourcingWeek,
		};
		const matrixData = await CommonServices.getMatrixData(apiData);

		if (matrixData.data) {
			const { data } = matrixData;
			console.log('Response RFQ data', data);
			dispatch({
				type: ACTIONS.GET_PURCHASE_MATRIX,
				payload: {
					table_header_weeks: [...data.shipment_dates, data.shipment_week], //needs to get from BE
					matrix_data_9_8: data.full_matrix_combined,
					matrix_data_9_7: data.full_active_matrix,
					matrix_data_9_1: data.matrix_row_total,
					matrix_dimension_status: data.matrix_dimension_status,
					each_row_price_total: data.active_admin_offer_full,
					each_row_volume_total: data.weight_class_volumes,
					shipment_date: data.shipment_date,
					matrix_id: data.matrix_id,
					selectedSourcingWeek: data.sourcing_week,
					sourcing_week: data.sourcing_week,
					shipment_week: data.shipment_week,
					current_week: data.current_week,
					sourcing_date: data.sourcing_date,
				},
			});
		} else {
			console.error(matrixData.error);
		}
	};

	const renderMatrixModel = (matrix_dimension_status) => {
		switch (matrix_dimension_status) {
			case 1:
				return {
					matrix: state.matrix_data_9_1,
					type: '9_1',
				};
			case 2:
				return { matrix: state.matrix_data_9_7, type: '9_7' };
			case 3:
				return { matrix: state.matrix_data_9_8, type: '9_8' };
			default:
				return {};
		}
	};

	const handleRowVolumeChange = (e, weightClass, index) => {
		if (e.target.value === '') {
			e.target.value = 0;
		}
		e.target.value = parseInt(e.target.value) || 0;
		e.target.value =
			e.target.value <= 0 ? 0 : e.target.value >= 270 ? 270 : e.target.value;

		//SETTING DISPLAY MATRIX VALUE
		let tempMatrix = JSON.parse(JSON.stringify(state.display_matrix));
		console.log('Temp Matrix', tempMatrix);

		if (state.enable_subtractor) {
			let comparison_difference =
				state.display_matrix[weightClass][index].volume - e.target.value;

			tempMatrix[weightClass][7].volume += comparison_difference;
		}

		tempMatrix[weightClass][index].volume = parseInt(e.target.value);

		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'display_matrix',
				value: tempMatrix,
			},
		});

		//HANDLE CONCURRENT VOLUME UPDATES
		let temp_each_row_volume_total = JSON.parse(
			JSON.stringify(state.each_row_volume_total)
		);
		let sum_volumes = 0;
		tempMatrix[weightClass].map((item) => {
			sum_volumes += item.volume;
		});
		temp_each_row_volume_total[weightClass] = sum_volumes;
		if (!state.enable_subtractor)
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'each_row_volume_total',
					value: temp_each_row_volume_total,
				},
			});

		//CHECK MATRIX EQUALITY
		let checkMatrixEquals = Object.entries(
			renderMatrixModel(state.matrix_dimension_status).matrix
		).every((item) =>
			item[1].every(
				(val, index) => tempMatrix[item[0]][index].volume === val.volume
			)
		);
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'matrix_volume_update',
				value: !checkMatrixEquals,
			},
		});

		if (checkMatrixEquals) {
			if (state.retrieved_matrix_dimensional_status !== 2) {
				if (state.enable_subtractor) {
					dispatch({
						type: ACTIONS.SET_FIELDS,
						payload: {
							field: 'disable_options_nine_star',
							value: ['9_7', '9_1'],
						},
					});
					dispatch({
						type: ACTIONS.DISABLE_SELECTORS,
					});
				} else {
					dispatch({
						type: ACTIONS.SET_FIELDS,
						payload: {
							field: 'disable_options_nine_star',
							value: ['9_7'],
						},
					});
				}
			} else
				dispatch({
					type: ACTIONS.SET_FIELDS,
					payload: {
						field: 'disable_options_nine_star',
						value: [],
					},
				});
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'is_split_disabled',
					value: false,
				},
			});
		} else {
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'disable_options_nine_star',
					value: ['9_1', '9_7', '9_8'],
				},
			});
			dispatch({
				type: ACTIONS.SET_FIELDS,
				payload: {
					field: 'is_split_disabled',
					value: true,
				},
			});
		}
	};

	const handleRowPriceChange = (priceValue, weightClass) => {
		let temp_each_row_price_total = state.each_row_price_total;
		let total_usd = 0;

		temp_each_row_price_total[weightClass] = parseFloat(priceValue);

		// Object.entries(temp_each_row_price_total).map((priceItem) => {
		// 	if (priceItem[1] !== null) total_usd += parseInt(priceItem[1]);
		// 	else return null;
		// });
		Object.entries(state.display_matrix).map((item) => {
			if (temp_each_row_price_total[item[0]] !== null) {
				let row_volumes = 0;
				item[1].map((val) => {
					row_volumes += val.volume;
				});
				total_usd += row_volumes * 22 * temp_each_row_price_total[item[0]];
			}
		});

		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'each_row_price_total',
				value: temp_each_row_price_total,
			},
		});
		dispatch({
			type: ACTIONS.SET_FIELDS,
			payload: {
				field: 'total_matrix_usd',
				value: total_usd,
			},
		});
	};

	return (
		<div className='fc_scroll'>
			{state.selectedSourcingWeek !== '' && state.matrix_id !== 0 ? (
				<table className='table-new'>
					<WeekHeader
						matrix_dimension_status={state.matrix_dimension_status}
						enable_subtractor={state.enable_subtractor}
						table_header_weeks={state.table_header_weeks}
						shipment_date={state.shipment_date}
					/>
					<tbody
						style={{ overflowX: 'auto', width: '100%', position: 'relative' }}
						className='fc_scroll'
					>
						{state.weight_classes.map((key, i) => {
							return (
								<Fragment key={i}>
									<WeightRow
										prices={state.each_row_price_total[key]}
										weightClass={key}
										volumes={state.each_row_volume_total[key]}
										rowData={state.display_matrix[key]}
										handleRowVolumeChange={handleRowVolumeChange}
										handleRowPriceChange={handleRowPriceChange}
										matrix_row_total={state.each_row_volume_total}
										splitEnabled={state.enable_subtractor}
										matrix_dimension_status={state.matrix_dimension_status}
										current_week={state.current_week}
										selected_sourcing_week={state.selectedSourcingWeek}
										refresh_button_clicked={state.refresh_button_clicked}
									/>
								</Fragment>
							);
						})}
					</tbody>
				</table>
			) : (
				<p className={'no-data'}>
					{state.selectedSourcingWeek == ''
						? 'No week is selected'
						: 'No data available'}
				</p>
			)}
		</div>
	);
};

const WeekHeader = ({
	matrix_dimension_status = '',
	enable_subtractor = false,
	table_header_weeks = [],
	shipment_date = '',
}) => {
	return (
		<thead>
			<tr>
				<th></th>
				<th></th>
				{matrix_dimension_status !== 1 ? (
					table_header_weeks.map((val, i) => {
						if (
							matrix_dimension_status !== 3 &&
							table_header_weeks.length - 1 === i
						) {
							return <Fragment key={i}></Fragment>;
						}
						return <th key={i}>{val}</th>;
					})
				) : (
					<th>{shipment_date}</th>
				)}
				{enable_subtractor && <th>Total</th>}
				<th></th>
			</tr>
		</thead>
	);
};

const WeightRow = ({
	prices = Number(),
	volumes = Number(),
	weightClass = '',
	matrix_row_total,
	rowData = [],
	splitEnabled = false,
	current_week,
	selected_sourcing_week,
	refresh_button_clicked,

	handleRowVolumeChange = (e, weightClass, index) => {
		e.preventDefault();
		console.log(
			`target Value ${e.target.value} with weightClass ${weightClass} in Index:${index}`
		);
	},
	handleRowPriceChange = (priceValue, weightClass) => {
		console.log(`price value ${priceValue} with weightClass ${weightClass}`);
	},
	matrix_dimension_status = '',
}) => {
	const [priceModal, setPriceModal] = React.useState(false);
	const [toggleEditRow, setToggleEditRow] = React.useState(false);
	let { purchase_id } = useParams();
	useEffect(() => {
		if (splitEnabled && volumes) setToggleEditRow(true);
		else setToggleEditRow(false);
	}, [splitEnabled]);

	useEffect(() => {
		setToggleEditRow(false);
	}, [matrix_dimension_status, selected_sourcing_week, refresh_button_clicked]);

	const priceStatus = async (status, value = 0, weightClass = '') => {
		if (status === 2) {
			setPriceModal(false);
			handleRowPriceChange(value, weightClass);
			const PurchasePriceLine = await PurchaseServices.getPurchasePriceLine({
				price: parseFloat(value),
				weight_class: weightClass,
				purchase_id: purchase_id,
			});
			if (PurchasePriceLine.data) {
				ToastsStore.success('Price Added');
			} else {
				ToastsStore.error('Price Adding failed');
			}
		} else {
			setPriceModal(false);
		}
	};

	return (
		<tr
			style={{
				background: `${volumes ? '#00ffff66' : '#00ffff26'}`,
			}}
		>
			{priceModal && (
				<AddPriceModal
					id={localStorage.rfqTenderId}
					weightClass={weightClass}
					priceStatus={priceStatus}
				/>
			)}
			{current_week == selected_sourcing_week ? (
				<td style={{ background: '#fff' }}>
					<MdModeEdit
						onClick={() => {
							if (prices || prices !== null)
								confirmAlert({
									message: 'You can perform this only if you are agreed with producer',
									buttons: [
										{
											label: 'Yes',
											onClick: () => {
												setPriceModal(true);
											},
										},
										{
											label: 'Cancel',
											onClick: () => false,
										},
									],
								});
							else setPriceModal(true);
						}}
						size={20}
						className={''}
						color={'grey'}
						style={{ cursor: 'pointer' }}
					/>
				</td>
			) : (
				<td></td>
			)}
			<td className='td-hog'>
				<span style={volumes ? {} : { opacity: 0.3 }}>
					<span>HOG {weightClass} kg</span>
					{volumes !== 0 ? (
						<span style={{ fontSize: 14 }}>
							&nbsp;(Total {volumes} {volumes === 1 ? 'Box' : 'Boxes'})
						</span>
					) : null}
				</span>
			</td>
			{rowData.map((row, i) => (
				<td key={i}>
					{splitEnabled && i === rowData.length - 1 ? (
						<input
							value={row.volume === 0 ? '' : row.volume}
							disabled={true}
							type='number'
							className='volume-input-disabled'
						/>
					) : (
						<input
							value={row.volume === 0 ? '' : row.volume}
							disabled={!toggleEditRow}
							type='number'
							className={` ${
								toggleEditRow ? 'volume-input' : 'volume-input-disabled'
							}`}
							onChange={(e) => {
								e.preventDefault();
								if (e.target.value >= 0) handleRowVolumeChange(e, weightClass, i);
							}}
						/>
					)}
				</td>
			))}
			{splitEnabled && <td>{matrix_row_total[weightClass]}</td>}
			<td className='td-kg'>
				{prices === null && volumes ? (
					<span>kr _ _/kg &nbsp;</span>
				) : prices !== null ? (
					<span>{formatCurrency(prices, 'NOK')}/kg</span>
				) : (
					<></>
				)}
				{current_week == selected_sourcing_week && (
					<FiChevronRight
						className={` ${toggleEditRow ? 'toggle-on' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							setToggleEditRow(!toggleEditRow);
						}}
						style={{ height: 20, width: 20, cursor: 'pointer' }}
					/>
				)}
			</td>
		</tr>
	);
};

const AddPriceModal = ({ priceStatus = () => {}, weightClass }) => {
	const [priceValue, setPriceValue] = React.useState(0);

	return (
		<div className={'modal_overlay'}>
			<div className={'Counter_body'}>
				<p>
					Add Price
					<div
						className={'close_pop'}
						onClick={(event) => {
							event.preventDefault();
							priceStatus(1);
						}}
					></div>
				</p>

				<div className={'of_wrp'}>
					<section>
						<span>Weight Class</span>
						<div className={'prc_out'}>
							<label>{weightClass} kg</label>
						</div>
					</section>
					<section>
						<span>Price</span>
						<div>
							<input
								style={{ width: '100%', marginTop: 10 }}
								type='number'
								value={priceValue}
								onChange={(event) => {
									event.preventDefault();
									if (event.target.value >= 0)
										setPriceValue(parseFloat(event.target.value));
								}}
							/>
						</div>
					</section>
					<section>
						<div
							className={`prc_out ask_button sp_btn ${
								!priceValue || typeof priceValue === String || priceValue === 0
									? 'disable-btn'
									: ''
							}`}
							style={{ marginLeft: 10 }}
							onClick={(event) => {
								event.preventDefault();
								if (priceValue > 0) priceStatus(2, priceValue, weightClass);
							}}
						>
							<>Submit</>
						</div>
					</section>
				</div>
			</div>
		</div>
	);
};

export default PurchaseDetail;
