import React, { useState, useEffect } from 'react'

const Species = ({language}) => {
    const [species, setSpecies] = useState([])

    useEffect(() => {
        async function fetchAPI() {
            var url = 'https://exocoetidae.no/product/api/species/'
            if ({language}) {
                url = url.concat(`?language=${language}`)
            }
            const headers = {
                'Authorization': '558ef807-ad2b-4aba-8629-c8d227745493'
            } 
            const apiResponse = await fetch(url, {headers:headers}).then(res => res.json())
            console.log(apiResponse)
            console.log(url)
            const species = apiResponse.result
            setSpecies(species)
            console.log(species)
        }
        fetchAPI()
    }, [language])

    return (
        <div>
            <ul>
                {species.map((spec) => (
                    <li id={spec.binomial_name}>{language ? spec.name_locale : spec.name_official} ({spec.binomial_name})</li>
                ))}  
            </ul>
        </div>
    )
}

export default Species